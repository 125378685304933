import {trackEvent} from '@hconnect/common/logging/Analytics'
import LogoutIcon from '@mui/icons-material/Logout'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined'
import {Box, Divider, MenuItem, MenuList, Typography} from '@mui/material'
import classNames from 'classnames'
import get from 'lodash/get'
import React, {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {Customer} from '../../Organisms/Customers/Customers.types'
import {AppState} from '../../Root.store'
import {CustomerPanelProfile} from '../CustomerFeedbackProgramme/CustomerFeedbackProfile/CustomerPanelProfile'
import {Overlay} from '../Overlay/Overlay'

import {AccountButton} from './AccountButton'
import {useAccountMenuStyles} from './AccountMenuStyles'
import {AccountsMenu} from './AccountsMenu'
import {Copyright} from './Copyright'
import {LanguageMenu} from './LanguageMenu'
import {LegalTermsAndConditions} from './LegalTermsAndConditions'

export type Locales = {
  defaultLocale: string
  locales: {code: string; name: string}[]
}
type AccountMenuProps = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  name: string
  email: string
  customers: Customer[]
  locales: Locales
  selectLanguage: (language: string) => void
  logout: () => void
  mhcUrl?: string
  notificationSettingsUrl?: string
}

enum UserIconColor {
  orange = 'orange',
  purple = 'purple',
  green = 'green',
  blue = 'blue'
}

export const AccountMenu: React.FC<AccountMenuProps> = ({
  isOpen,
  setOpen,
  name,
  email,
  customers,
  locales,
  selectLanguage,
  logout,
  mhcUrl,
  notificationSettingsUrl
}) => {
  const classes = useAccountMenuStyles()
  const {t} = useTranslation()
  const menuNodeRef = useRef<HTMLDivElement | null>(null)

  let color
  switch (email.length % 4) {
    case 0:
      color = UserIconColor.orange
      break
    case 1:
      color = UserIconColor.purple
      break
    case 2:
      color = UserIconColor.green
      break
    case 3:
      color = UserIconColor.blue
  }
  const [subMenu, setSubMenu] = useState<JSX.Element | null>(null)
  const accounts = customers
    .filter((customer) => typeof customer.customerNumber === 'string')
    .map((customer) => String(customer.customerNumber))

  const getInitials = (name: string): string => {
    const trimmedName = name.trim()
    const names = trimmedName.split(' ')
    const initials = names.length > 1 ? names[0][0] + names[names.length - 1][0] : names[0][0]
    return initials.toLocaleUpperCase()
  }
  const user = useSelector((state: AppState) => get(state, ['userProfile', 'userProfile']))
  const userIcon = <Typography variant="h4">{getInitials(name)}</Typography>

  useEffect(() => {
    isOpen
      ? document.addEventListener('mousedown', handleClickOutside)
      : document.removeEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen])

  const handleClickOutside = (e: MouseEvent) => {
    if ((menuNodeRef.current as HTMLDivElement)?.contains(e.target as Node)) {
      return // Inside click
    }

    setOpen(false) // Outside click
  }

  const dataLinkPortalLink =
    process.env.REACT_APP_STAGE === 'prod'
      ? 'https://datalink.hconnect.digital/sign-up'
      : `https://datalink-${process.env.REACT_APP_STAGE}.hconnect.digital/sign-up`

  return (
    <>
      <AccountButton
        open={isOpen}
        onClick={() => {
          setOpen(!isOpen)
          setSubMenu(null)
        }}
        className={classNames({[classes[color]]: !isOpen}, classes.accountButton)}
        userIcon={userIcon}
      />

      <Overlay
        data-test-id="account-menu-overlay"
        isVisible={isOpen}
        overlayClassName={classes.overlay}
        contentClassName={classNames(classes.overlayContent, {
          [classes.subMenuOpen]: subMenu !== null
        })}
      >
        <div ref={menuNodeRef}>
          <MenuList className={classes.menu}>
            <Box className={classes.item}>
              <Box className={classNames(classes.userIcon, classes[color])}>
                {getInitials(name)}
              </Box>
              <Typography className={classes.name}>{name}</Typography>
              <Typography className={classes.email}>{email}</Typography>
            </Box>
            <Divider className={classes.divider} />
            {accounts.length > 0 && (
              <div>
                <AccountsMenu
                  accounts={accounts}
                  openSubMenu={setSubMenu}
                  data-test-id="account-accountsMenu"
                />
                <Divider className={classes.divider} />
              </div>
            )}
            {dataLinkPortalLink && (
              <>
                <MenuItem
                  className={classes.item}
                  component="a"
                  target="_blank"
                  href={dataLinkPortalLink}
                  data-test-id="data-link-portal-link"
                >
                  <Box>
                    <Typography className={classes.account} sx={{fontWeight: 600}}>
                      {t('orderIntake.dataLinkDevPortal.menuLabel')}
                    </Typography>
                    <Box display='flex'>
                      <Typography variant="body2" sx={{ wordBreak: 'break-word', whiteSpace: 'pre-line'}}>
                        {t('orderIntake.dataLinkDevPortal.menuLink')}
                      </Typography>
                      <OpenInNewIcon className={classNames(classes.iconRight)} />
                    </Box>
                  </Box>
                </MenuItem>
                <Divider className={classes.divider} />
              </>
            )}
            <CustomerPanelProfile openSubMenu={setSubMenu} isSubMenuOpen={subMenu} />
            {notificationSettingsUrl ? (
              <div>
                <MenuItem
                  className={classes.item}
                  component="a"
                  href={notificationSettingsUrl}
                  data-test-id="show-notification-settings"
                >
                  <MarkEmailUnreadOutlinedIcon className={classNames(classes.icon)} />
                  <Typography>{t('orderIntake.notificationSettings.menuLabel')}</Typography>
                </MenuItem>
                <Divider className={classes.divider} />
              </div>
            ) : null}
            <LanguageMenu
              languages={locales}
              selectLanguage={selectLanguage}
              openSubMenu={setSubMenu}
              data-test-id="account-languageMenu"
            />
            <Divider className={classes.divider} />
            <LegalTermsAndConditions user={user} openSubMenu={setSubMenu} />
            <Divider className={classes.divider} />
            {mhcUrl ? (
              <div>
                <MenuItem
                  className={classes.item}
                  component="a"
                  href={mhcUrl}
                  onClick={() => trackEvent('hubCustomerAdminAccessed', {userId: user?.user_id})}
                  data-test-id="account-userAdminLink"
                >
                  <Typography>{t('header.userAdmin')}</Typography>
                  <OpenInNewIcon className={classNames(classes.iconRight)} />
                </MenuItem>
                <Divider className={classes.divider} />
              </div>
            ) : null}
            <MenuItem className={classes.item} onClick={logout} data-test-id="account-logout">
              <LogoutIcon className={classNames(classes.icon, classes.logoutIcon)} />
              <Typography>{t('header.logOut')}</Typography>
            </MenuItem>
            <Divider className={classes.divider} />
            <MenuItem className={classes.item} data-test-id="copyright">
              <Copyright />
            </MenuItem>
          </MenuList>
          {subMenu}
        </div>
      </Overlay>
    </>
  )
}
