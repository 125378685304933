import {ReactComponent as LogoHM} from '@hconnect/common/branding/branding/icons/Logo-HM.svg'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {getBrowserLanguage} from '@hconnect/common/utils'
import {
  Banner,
  DefaultFooter,
  Divider,
  EnvAppInfoContainer,
  Grid,
  HCTheme,
  isCookieBannerShown,
  LegalDocumentType,
  Logos,
  TermsAndPrivacyModal,
  Typography,
  useOreo
} from '@hconnect/uikit'
import HubSvg, {ReactComponent as HubLogoSvg} from '@hconnect/uikit/src/common/assets/Hub-Logo.svg'
import {Box, Button, Link, Paper} from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import {useLocation} from 'react-router'

import {loginFlow} from './App.global'
import {getConfig} from './Molecules/common/oreoSettings'
import Content from './Molecules/Content'
import {LegalLinks, LegalLinkType} from './Molecules/LegalLinks'
import {useBranding} from './Organisms/Branding'
import {fetchFeatures, useFeaturesDispatch, useFeaturesState} from './Organisms/Features'
import {LegalType} from './Pages/Legal/types'
import {useMaintenanceScreen} from './Pages/MaintenanceScreen/MaintenanceScreenProvider'
import {useMaintenanceMode} from './Pages/MaintenanceScreen/useMaintenanceMode'

const SKIP_LANDINGPAGE_SESSION_STORAGE_KEY = 'skipLandingPage'
export const getDocumentType = (modalType: string) => {
  switch (modalType) {
    case 'privacy-hub':
      return LegalDocumentType.PRIVACY
    case 'terms-hub':
      return LegalDocumentType.TERMS
    case 'imprint-hub':
      return LegalDocumentType.IMPRINT
    case 'disclaimer-hub':
      return LegalDocumentType.IMPRINT
    default:
      return LegalDocumentType.NONE
  }
}
export const LandingPage = () => {
  const {pathname} = useLocation()
  const {t} = useTranslation()
  const features = useFeaturesState()
  const dispatch = useFeaturesDispatch()
  const reduxDispatch = useDispatch()
  const {
    contactEmailHref,
    nationalBrandName,
    nationalUrl,
    nationalLogoUrl,
    onWhiteLogoUrl,
    country,
    termsAndConditionsOfSale,
    termsOfSales,
    safetyInstructionsAndGeneralTermsOfSaleRMC,
    safetyInstructionsAndGeneralTermsOfSaleAGG
  } = useBranding()

  const [modalType, setModalType] = React.useState<LegalType | undefined>(undefined)

  const {getResolutionByNameAndConstraint, normalized} = features
  const [accepted] = useOreo()
  const [showBanner, setShowBanner] = useState<boolean>(
    isCookieBannerShown(accepted) ? true : false
  )

  useEffect(() => {
    // When the corporate website links to hub.heidelbergcement.com/login
    // we will skip the landing page and redirect directly to the authenticator
    // and we will remember to skip the landing page, until the user logs out.
    if (pathname === '/login') {
      sessionStorage.setItem(SKIP_LANDINGPAGE_SESSION_STORAGE_KEY, '1')
      void loginFlow.startLoginProcess()
    } else {
      const skipLandingPage = sessionStorage.getItem(SKIP_LANDINGPAGE_SESSION_STORAGE_KEY) === '1'
      if (skipLandingPage) {
        sessionStorage.removeItem(SKIP_LANDINGPAGE_SESSION_STORAGE_KEY)
        void loginFlow.startLoginProcess()
      } else {
        void fetchFeatures(dispatch, true)
      }
    }
  }, [pathname, dispatch, country, reduxDispatch])

  const {setMaintenanceState} = useMaintenanceScreen()
  const {isFetching: maintenanceModeFetching, data: maintenanceMode} = useMaintenanceMode()

  useEffect(() => {
    // check for maintenance mode
    setMaintenanceState && maintenanceMode && setMaintenanceState(maintenanceMode)
  }, [maintenanceModeFetching])

  const preferredLanguage = getBrowserLanguage()

  const year = new Date().getFullYear()

  const handleLogin = (e) => {
    e.preventDefault()
    void loginFlow.startLoginProcess()
  }

  const handleRegister = (e) => {
    e.preventDefault()
    const channel = new URL(window.location.href).searchParams.get('channel')
    void loginFlow.startRegistrationProcess('request-access', '', channel || 'hub')
  }

  if (pathname === '/login') {
    return null
  }

  const disclaimerLink: LegalLinkType = {
    key: 'disclaimer',
    title: t('accountMenu.legal.disclaimer'),
    endpoint: '#',
    target: '_self',
    onClick: () => setModalType('disclaimer-hub'),
    isInternal: false
  }

  const touLink: LegalLinkType = {
    key: 'termsofuse',
    title: t('accountMenu.legal.termsofuse'),
    endpoint: '#',
    target: '_self',
    onClick: () => setModalType('terms-hub'),
    isInternal: false
  }

  const dppLink: LegalLinkType = {
    key: 'privacy',
    title: t('accountMenu.legal.privacy'),
    endpoint: '#',
    target: '_self',
    onClick: () => setModalType('privacy-hub'),
    isInternal: false
  }

  const tacOfSaleLink: LegalLinkType = {
    key: 'termsconditionsofsale',
    title: t('accountMenu.legal.saleConditions'),
    endpoint: termsAndConditionsOfSale,
    isInternal: false
  }

  const termsOfSalesLink: LegalLinkType = {
    key: 'termsOfSales',
    title: t('accountMenu.legal.termsOfSales'),
    endpoint: termsOfSales,
    isInternal: false
  }

  const safetyInstructionsAndGeneralTOSRMC: LegalLinkType = {
    key: 'safetyInstructionsAndGeneralTermsOfSaleRMC',
    title: t('accountMenu.legal.safetyInstructionsAndGeneralTermsOfSaleRMC'),
    endpoint: safetyInstructionsAndGeneralTermsOfSaleRMC,
    isInternal: false
  }

  const safetyInstructionsAndGeneralTOSAGG: LegalLinkType = {
    key: 'safetyInstructionsAndGeneralTermsOfSaleAGG',
    title: t('accountMenu.legal.safetyInstructionsAndGeneralTermsOfSaleAGG'),
    endpoint: safetyInstructionsAndGeneralTermsOfSaleAGG,
    isInternal: false
  }

  const imprintLink: LegalLinkType = {
    key: 'imprint',
    title: t('accountMenu.legal.imprint'),
    endpoint: '#',
    target: '_self',
    onClick: () => setModalType('imprint-hub'),
    isInternal: false
  }

  const contactLink: LegalLinkType = {
    key: 'contact',
    title: t('accountMenu.legal.contact'),
    endpoint: contactEmailHref,
    isInternal: false,
    onClick: () => {
      trackEvent('hubContact', {country: country})
    }
  }

  const legalLinks: LegalLinkType[] = [
    ...(getResolutionByNameAndConstraint('Disclaimer', normalized, 'countryId').enabled.includes(
      country as string
    )
      ? [disclaimerLink]
      : []),
    touLink,
    ...(getResolutionByNameAndConstraint(
      'TermsAndConditionsOfSale',
      normalized,
      'countryId'
    ).enabled.includes(country as string)
      ? [tacOfSaleLink]
      : []),
    ...(getResolutionByNameAndConstraint('TermsOfSales', normalized, 'countryId').enabled.includes(
      country as string
    )
      ? [termsOfSalesLink]
      : []),
    ...(getResolutionByNameAndConstraint(
      'DifferentLinkForSafetyInstructionsAndGeneralTermsOfSalesForAGGAndRMC',
      normalized,
      'countryId'
    ).enabled.includes(country as string)
      ? [safetyInstructionsAndGeneralTOSRMC, safetyInstructionsAndGeneralTOSAGG]
      : []),
    dppLink,
    ...(getResolutionByNameAndConstraint('Imprint', normalized, 'countryId').enabled.includes(
      country as string
    )
      ? [imprintLink]
      : []),
    contactLink
  ]

  const versionAndCopyright: LegalLinkType[] = [
    {
      key: 'copyright',
      title: t('accountMenu.legal.copyright', {year, nationalBrandName}),
      endpoint: nationalUrl,
      isInternal: false
    },
    {
      key: 'version',
      title: <EnvAppInfoContainer />,
      href: '#',
      isInternal: true
    }
  ]

  const onlyNecessaryAllowed = getResolutionByNameAndConstraint(
    'OnlyNecessaryCookiesOption',
    normalized,
    'countryId'
  ).enabled.includes(country as string)

  const renderLegalModal = () =>
    modalType && (
      <TermsAndPrivacyModal
        showFooter={false}
        hideAcceptanceTitle
        preferredLanguage={preferredLanguage}
        documentType={getDocumentType(modalType)}
        onClose={() => {
          setModalType(undefined)
        }}
        onAccept={() => {}}
        logo={HubLogoSvg}
        country={country}
      />
    )

  return (
    <Content>
      {renderLegalModal()}
      {showBanner && (
        <Banner
          labels={getConfig(t, onlyNecessaryAllowed).bannerLabels}
          policyInformation1={getConfig(t).policyInformation1}
          policyInformation2={getConfig(t).policyInformation2}
          topics={getConfig(t).topics}
          theme={HCTheme}
          onClose={() => setShowBanner(false)}
        />
      )}
      <Grid container fullHeight alignItems="flex-end">
        <Grid item xs={1} md={2} lg={3} xl={4} />
        <Grid item xs={10} md={8} lg={6} xl={4}>
          <Paper elevation={4}>
            <Box
              sx={{py: 11.25}}
              display="flex"
              flexDirection="column"
              alignItems="center"
              data-test-id="jonas"
            >
              <Logos
                style={{
                  fontSize: '1.1em',
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'space-around',
                  padding: '0 90px'
                }}
              >
                <img src={HubSvg} alt="HubSvg" data-test-id="landing-page-logo-hub" />
                {!nationalUrl?.match(/heidelbergmaterials/) ? (
                  <img
                    src={onWhiteLogoUrl || nationalLogoUrl}
                    alt="nationalLogoUrl"
                    data-test-id="landing-page-logo-national"
                  />
                ) : (
                  <LogoHM />
                )}
              </Logos>
              <Box minHeight={40} />
              <Box px={5}>
                <Button
                  color="primary"
                  onClick={handleLogin}
                  data-test-id="landing-page-button-login-hconnect"
                >
                  {t('landingPage.loginButton')}
                </Button>
              </Box>
              <Box px={5}>
                {!getResolutionByNameAndConstraint(
                  'LandingPageRequestAccess',
                  normalized,
                  'countryId'
                ).disabled.includes(country as string) && (
                  <>
                    <Box minHeight={30} />
                    <Typography
                      variant="body1"
                      color="secondary"
                      data-test-id="landing-page-request-access"
                    >
                      {t('landingPage.requestAccessText')}{' '}
                      <Link
                        onClick={handleRegister}
                        data-test-id="landing-page-request-access-link"
                        href="#"
                      >
                        {t('landingPage.requestAccessLink')}
                      </Link>
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid container item xs={12} direction="column">
          <Divider variant="middle" color="light" />
          <Grid container item xs={12} direction="row">
            <Grid item xs={1} md={2} lg={3} xl={4} />
            <Grid item xs={10} md={8} lg={6} xl={4}>
              <Box p={2} pl={0} pr={0} maxHeight={150}>
                <DefaultFooter
                  onDark
                  copyright={<LegalLinks links={versionAndCopyright} />}
                  links={<LegalLinks links={legalLinks} />}
                />
              </Box>
            </Grid>
            <Grid item xs={1} md={2} lg={3} xl={4} />
          </Grid>
        </Grid>
      </Grid>
    </Content>
  )
}
