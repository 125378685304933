import {Box, Typography} from '@mui/material'

import {EvoZeroBadge} from './EvoZeroBadge'

interface IMaterialDescription {
  materialDescription?: string
  customerMaterialDescription?: string
  isCarbonSavingProduct?: boolean
}
const MaterialDescription = ({
  materialDescription,
  customerMaterialDescription,
  isCarbonSavingProduct
}: IMaterialDescription) => {
  return (
    <>
      <Box display="flex" flexDirection="column" style={{gap: 2}}>
        <EvoZeroBadge isCarbonSavingProduct={isCarbonSavingProduct} />
        {materialDescription}
      </Box>
      {customerMaterialDescription ? (
        <Typography variant="body1" color="secondary" paragraph style={{wordBreak: 'break-word'}}>
          {customerMaterialDescription}
        </Typography>
      ) : null}
    </>
  )
}

export default MaterialDescription
