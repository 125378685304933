import {useEffect, useState} from 'react'

type ActivePopup = 'MaterialEditor' | 'OrderSummary' | undefined

export const useActivePopup = (
  setActiveOrderBox: () => void,
  activeMaterialForm?: string,
  editButtonCLicked?: boolean
) => {
  const [activePopup, setActivePopup] = useState<ActivePopup>(undefined)

  useEffect(() => {
    if (activePopup === 'MaterialEditor' && activeMaterialForm) return
    if (activePopup === undefined && activeMaterialForm === undefined) return

    if (activeMaterialForm === undefined) {
      setActivePopup(undefined)
    } else if (editButtonCLicked) {
      setActivePopup('MaterialEditor')
    } else {
      setActivePopup(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMaterialForm, editButtonCLicked])
  useEffect(() => {
    if (activePopup === 'MaterialEditor') return
    setActiveOrderBox()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePopup])
  return [activePopup, setActivePopup] as const
}
