import EvoZerSvg from '../Assets/evoZero.svg'

export const EvoZeroBadge = ({isCarbonSavingProduct}: {isCarbonSavingProduct?: boolean}) => {
  if (!isCarbonSavingProduct) return null

  return (
    <img
      src={EvoZerSvg}
      alt="EvoZero Badge"
      style={{height: 8, alignSelf: 'start'}}
      data-test-id="evo-zero-badge"
    />
  )
}
