import {BusinessLineType} from '../../common/types'
import {checkEmptyTimeZone} from '../../Organisms/OrderIntake/utils'
import {ShippingType} from '../declarations/OrderIntake.enums'
import {
  OptimizedMaterialDto,
  OptimizedOption,
  OrderIntakeMaterialOptionPayload,
  QuantityType
} from '../declarations/types'

type OptionResult = OrderIntakeMaterialOptionPayload
type OptionResults = OptionResult[]
export const mapOptimizedOptionsToLegacy = (
  o: OptimizedOption[],
  applyMaterialEnteredDescription: boolean,
  applyContractItemDescription: boolean
): OptionResults => {
  const result = o.reduce((siteResult, site) => {
    const optionsToSiteResult = site.contractUnit.reduce((contractUnitResult, contract) => {
      // eslint-disable-next-line complexity
      const optionsToContractUnit = contract.materials.reduce((materialResult, material) => {
        const option: OptionResult = {
          businessHours: {
            ...site.businessHours[material.shippingType.toLowerCase()],
            timeZone: checkEmptyTimeZone(
              material.shippingType === ShippingType.DELIVER
                ? site.businessHours[material.shippingType.toLowerCase()].timeZone
                : contract.timeZone
            )
          },
          canDisableEmailSending: !!material.canDisableEmailSending,
          contact: contract.contact,
          customerNumber: contract.customerNumber ?? '',
          customerReference: material.poNumber ?? '',
          dateRange: {
            ...site.dateRange[material.shippingType.toLowerCase()],
            from: contract.contractValidityPeriods[material.shippingType.toLowerCase()]
              .contractValidityStartDate,
            to: contract.contractValidityPeriods[material.shippingType.toLowerCase()]
              .contractValidityEndDate
          },
          shippingType: material.shippingType,
          deactivationReason: material.deactivationReason,
          defaultDeliveryWindow: site.defaultDeliveryWindow[material.shippingType.toLowerCase()],
          dispatchPhoneNumbers: site.dispatchPhoneNumbers,
          driverInstructions: contract.driverInstructions ?? '',
          invalid: !!material.invalid,
          material: {
            businessLine: material.businessLine ?? BusinessLineType.CEM,
            contractItemPositionNumber: material.contractItemPositionNumber ?? '',
            contractNumber: material.contractNumber ?? '',
            materialDescription: resolveMaterialDescription(
              material,
              applyMaterialEnteredDescription,
              applyContractItemDescription
            ),
            materialNumber: material.materialNumber ?? '',
            materialEnteredNumber: material.materialEnteredNumber ?? '',
            quantityType: material.quantityType ?? QuantityType.TruckCapacity,
            isCarbonSavingProduct: material.isCarbonSavingProduct ?? false,
          },
          plant: {
            city: site.plant.city ?? '',
            country: site.plant.country ?? '',
            plantName: site.plant.plantName ?? '',
            plantNumber: site.plant.plantNumber ?? '',
            postalCode: site.plant.postalCode ?? '',
            state: site.plant.state ?? '',
            street: site.plant.street ?? '',
            configurableSlots: site.plant.configurableSlots ?? []
          },
          shippingAddress: {
            city: contract.city ?? '',
            country: contract.country ?? '',
            postalCode: contract.postalCode ?? '',
            state: contract.state ?? '',
            street: contract.street ?? '',
            siteNumber: contract.siteNumber,
            siteName: contract.siteName ?? ''
          },
          truckCapacity: (contract.truckCapacity ?? {})[material.shippingType.toLowerCase()] ?? {}
        }
        materialResult.push(option)
        return materialResult
      }, [] as OptionResults)

      return contractUnitResult.concat(optionsToContractUnit)
    }, [] as OptionResults)

    return siteResult.concat(optionsToSiteResult)
  }, [] as OptionResults)
  return result
}

const resolveMaterialDescription = (
  material: OptimizedMaterialDto,
  applyMaterialEnteredDescription: boolean,
  applyContractItemDescription: boolean
): string => {
  if (applyMaterialEnteredDescription)
    return material.materialEnteredDescription
      ? material.materialEnteredDescription
      : material.materialDescription ?? ''

  if (applyContractItemDescription)
    return material.contractItemDescription
      ? material.contractItemDescription
      : material.materialDescription ?? ''

  return material.materialDescription ?? ''
}
